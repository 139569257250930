// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

.bg-indigo {
	background-color: purple !important;
}

.bg-dark-orange {
	background-color: darken($warning, 15%)  !important;
}

.bg-dark-blue {
	background-color: darken($primary, 15%)  !important;
}

.bg-light-blue {
	background-color: lighten($primary, 15%)  !important;
}

.bg-light-danger {
	background-color: lighten($danger, 40%);
}

.bg-light-warning {
	background-color: lighten($warning, 30%) !important;
}

@media screen {
	.screen-p-4 {
		padding:1.5rem !important;
	}
}

.env-highlight {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100px;
    line-height: 30px;
    color: white;
    font-size: 1.5em;
    font-weight: 400;
    text-align: center;
    border-top: 1px solid #333;
    border-right: 1px solid #333;
}

.large-tooltip {
	width: 400px;
	text-align: left;
}

.tooltip-left {
	text-align: left;
}

.tooltip-inner {
	max-width: 300px !important;
}

.substitute-bg-color {
	background-color: rgb(253, 126, 20) !important;
}

.text-shadow {
	text-shadow: 0 0 1px black;
}

.text-xxs {
	font-size: 0.5rem !important;
}

.text-xs {
	font-size: 0.7rem !important;
}

.text-sm {
	font-size: 0.9rem !important;
}

.hover-bg-light:hover {
	background-color: #ddd;
	cursor: pointer;
}

@keyframes fade-out-effect {
	0% {opacity : 1}
	100% {opacity : 0}
}

.fade-out {
	animation-name: fade-out-effect;
	animation-duration: 2s;
	animation-iteration-count: 1;
	animation-fill-mode: both;
}

.pt-10 {
	padding-top:70px;
}

.bg-semi-opaque {
	background-color: rgba(0, 0, 0, .25);
}

.sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	padding: 48px 0 0;
	box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar .nav-link {
	font-weight: 500;
	color: #333;
}

.sidebar .nav-link .feather {
	margin-right: 4px;
	color: #727272;
}

.sidebar .nav-link.active {
	color: #2470dc;
}

.navbar-brand {
	padding-top: .75rem;
	padding-bottom: .75rem;
	font-size: 1rem;
	background-color: rgba(0, 0, 0, .25);
	box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .navbar-toggler {
	top: .25rem;
	right: 1rem;
}

.faded {
	opacity: 0.5;
}

.faded-2 {
	opacity: 0.3;
}

.cursor-pointer {
    cursor: pointer;
	user-select: none;
}

.cursor-move {
    cursor: move;
	user-select: none;
}

.schedule-table {
	th, td {
		width : 90px;
	}

	td div {
		height: 50px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

}

th {
	a {
		text-decoration: none;
	}
}

.list-selected-line {
	cursor: pointer;
	user-select: none;

	&:hover {
		background-color: $primary;
		color:white;
	}
}

.parent-hover-visible {
	display: none;
}

.parent-hover-control:hover {
	.parent-hover-visible {
		display: block;
	}
}

.parent-hover-visible2 {
	display: none;
}

.parent-hover-control2:hover {
	.parent-hover-visible2 {
		display: block;
	}
}

.bg-light-green {
	background-color: rgba(171, 211, 171, 0.411) !important;
}

.fade-hover:hover {
	opacity: 0.5;
}

.table-no-padding {
	td {
		padding: 2px !important;
		vertical-align: middle;
	}
}